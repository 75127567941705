import axios from './http'
// 获取出入口列表
export const getEntrancesList = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetTreeList'
  })
}
// 修改音视频请求状态
export const modifyAudioRequestStatus = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AudioVideoService/SetAudioVideoType',
    data // {id:,name:,type:修改状态,parprakingID:}
  })
}
// 获取腾讯云配置信息
export const obtainTencentConfiguration = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetZSYSPInfo'
  })
}
// 获取出入口状态
export const getAccessStatus = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AudioVideoService/GetEntryAndExitStatus',
    data // {id:出入口id,parprakingID：主停车场id}
  })
}
// 获取视频地址
export const getVideoAddress = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetZSVideoUrl',
    params // {sn:设备序列号,type:flv}
  })
}
// 下发关闸指令
export const issueClosingCommand = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AudioVideoService/StartShutOffBrake',
    data
  })
}
// 下发开闸指令
export const issueOpeningCommand = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AudioVideoService/StartTurnOnBrake',
    data
  })
}
// 获取呼叫记录
export const getCallRecords = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetPageList',
    params
  })
}
// 获取开关闸记录
export const obtainSwitchRecord = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetDeviceSwitchPageList',
    params
  })
}
// 获取MQTT配置信息
export const getMqttConfiguration = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AudioVideoService/GetMqttInfo'
  })
}
